@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.table td {
  position: relative;
}

.table td input {
  position: absolute;
  display: block;
  top:0;
  left:0;
  margin: 0;
  height: 100%;
  width: 100%;
  border: none;
  padding: 10px;
  box-sizing: border-box;
}
.table td input:hover {
  border: solid 1px rgb(0, 0, 0);
}

.select-cell:hover {
  border: 1px solid rgb(0, 0, 0); /* Adjust the border style as needed */
}

.invalid-field {
  /* border-bottom: 1px solid red; */
  border: 1px solid red;
}
